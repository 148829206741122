import {PropsWithChildren, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'

import {Dispatch, RootState} from 'src/utilities/store'

interface TestGroupHostContainerProps extends PropsWithChildren {}

const Container = styled.div`
  display: grid;
`

const TestGroupHostContainer = ({children}: TestGroupHostContainerProps) => {
  const [clickCount, setClickCount] = useState<number>(0)

  const {testGroupHost, testGroupDomain, testGroupSubdomens} = useSelector((state: RootState) => state.app)

  const dispatch = useDispatch<Dispatch>()

  const handleClick = () => {
    if (clickCount === 4) {
      const groupHostValue = window.prompt('Enter group host value:', testGroupHost)
      if (groupHostValue !== null) {
        dispatch.app.setTestGroupHost(groupHostValue)
      }

      const groupDomainValue = window.prompt('Enter group domain value:', testGroupDomain)
      if (groupDomainValue !== null) {
        dispatch.app.setTestGroupDomain(groupDomainValue)
      }

      const groupSubdomensValue = window.prompt('Enter group subdomens value:', testGroupSubdomens)
      if (groupSubdomensValue !== null) {
        dispatch.app.setTestGroupSubdomens(groupSubdomensValue)
      }

      setClickCount(0)

      return
    }

    setClickCount((prevClickCount) => prevClickCount + 1)
  }

  return <Container onClick={handleClick}>{children}</Container>
}

export default TestGroupHostContainer
