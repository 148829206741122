import {useCallback, useEffect, useLayoutEffect, useMemo, useState} from 'react'
import {useMediaQuery as useQuery} from 'react-responsive'
import {useMatch} from 'react-router-dom'
import {validate as validateuuidv4} from 'uuid'
import {useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'

import {getHost, getMainHost, getNearestVenue, getWindowSizes} from './functions'
import {queries} from './theme'
import {RootState} from './store'
import {TranslationsKeys} from 'src/types'
import {TRANSLATIONS_KEYS} from './constants'
import {selectVenue} from 'src/models/group'

export const useWIndowSizes = () => {
  const [sizes, setSizes] = useState<{width: number; height: number}>(getWindowSizes())

  const handleResize = () => setSizes(getWindowSizes())

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return sizes
}

export const useMediaQuery = () => {
  return {
    isMobile: useQuery({query: queries.mobile}),
    isDesktop: useQuery({query: queries.desktop}),
  }
}

export const useElScrollTimeout = (el?: HTMLElement | null, cb?: () => void, timeout: number = 250) => {
  useLayoutEffect(() => {
    let scrollTimeout: NodeJS.Timeout | undefined = undefined

    el?.addEventListener('scroll', () => {
      clearTimeout(scrollTimeout)

      scrollTimeout = setTimeout(() => cb?.(), timeout)
    })

    return () => clearTimeout(scrollTimeout)
  }, [cb, el, timeout])
}

export const useDocScrollTimeout = (cb?: () => void, timeout: number = 250) => {
  useEffect(() => {
    let scrollTimeout: NodeJS.Timeout | undefined = undefined

    document.addEventListener('scroll', () => {
      clearTimeout(scrollTimeout)

      scrollTimeout = setTimeout(() => cb?.(), timeout)
    })

    return () => clearTimeout(scrollTimeout)
  }, [cb, timeout])
}

export const useValueByScroll = (counter: number, countTo: number, startCountFrom: number) => {
  const [value, setValue] = useState<number>(0)

  const handleScroll = useCallback(() => {
    if (window.scrollY <= startCountFrom) {
      setValue(0)
      return
    }

    if (window.scrollY - startCountFrom >= countTo) {
      setValue(counter)
      return
    }

    setValue((counter / countTo) * (window.scrollY - startCountFrom))
  }, [countTo, counter, startCountFrom])

  useEffect(() => {
    handleScroll()

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return value
}

export const useTabletId = () => {
  const lastTabletId = useSelector((state: RootState) => state.app.lastTabletId)
  const venues = useSelector((state: RootState) => state.group.group?.venues)

  const matchTabletId = useMatch({path: '/:tabletId', end: false})?.params?.tabletId

  return useMemo(() => {
    if (matchTabletId && validateuuidv4(matchTabletId)) {
      return matchTabletId
    }

    if (!venues?.length) {
      return
    }

    const foundVenue = venues.find((venue) => venue.tabletId === lastTabletId)
    if (foundVenue) {
      return foundVenue.tabletId
    }

    return venues[0].tabletId
  }, [lastTabletId, matchTabletId, venues])
}

export const useClickOutside = (el?: HTMLElement | null, cb?: () => void) => {
  const handleMouseDown = useCallback(
    (e: MouseEvent) => {
      if (!el || !e.target || el.contains(e.target as Node)) {
        return
      }

      cb?.()
    },
    [cb, el],
  )

  useEffect(() => {
    if (!el) {
      return
    }

    document.addEventListener('mousedown', handleMouseDown)

    return () => {
      document.removeEventListener('mousedown', handleMouseDown)
    }
  }, [el, handleMouseDown])
}

export const useTranslations = (): {[key in TranslationsKeys]: string} | undefined => {
  const languageCode = useSelector((state: RootState) => state.app.languageCode)
  const translations = useSelector((state: RootState) => state.translations.translations)

  const translationsObj = useMemo(() => {
    return Object.keys(TRANSLATIONS_KEYS).reduce<{[lngCode: string]: {[key in TranslationsKeys]: string}}>(
      (obj, keyA) => {
        const foundTranslation = translations.find((translation) => translation.term === keyA)
        if (!foundTranslation) {
          return obj
        }

        Object.entries(foundTranslation.translations ?? {}).forEach(([keyB, value]) => {
          if (!foundTranslation.term) {
            return
          }

          obj[keyB] = {...obj[keyB], [foundTranslation.term]: value}
        })

        return obj
      },
      {},
    )
  }, [translations])

  return translationsObj[languageCode]
}

export const useNearestRestaurant = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const venues = useSelector((state: RootState) => state.group.group?.venues)

  const getNearestRestaurant = async () => {
    setLoading(true)

    try {
      const nearestVenue = await getNearestVenue(venues ?? [])

      return nearestVenue
    } finally {
      setLoading(false)
    }
  }

  return {loading, getNearestRestaurant}
}

export const useChoiceCountTranslation = (count?: number) => {
  const {t} = useTranslation()

  return useMemo(() => {
    if (typeof count !== 'number') {
      return
    }

    if (count === 1) {
      return t('pages.product.components.optionsList.choiceCountOne', {count})
    }

    return t('pages.product.components.optionsList.choiceCountOther', {count})
  }, [count, t])
}

export const useHeaderTitle = () => {
  const tabletId = useTabletId()

  const group = useSelector((state: RootState) => state.group.group)
  const user = useSelector((state: RootState) => state.profile.user)

  const venue = useSelector((state: RootState) => selectVenue(state, tabletId))

  const {t} = useTranslation()

  return useMemo(() => {
    if (!group) {
      return user?.restaurantDisplayTitle ?? ''
    }

    if (getHost() === getMainHost() && venue) {
      return t('pages.restaurants.title')
    }

    return venue?.restaurantDisplayTitle ?? user?.restaurantDisplayTitle ?? ''
  }, [group, t, user?.restaurantDisplayTitle, venue])
}
